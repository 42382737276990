import { getDestinationSlug } from '~/helpers/getDestinationSlug'

export default defineNuxtRouteMiddleware(async (to) => {
  const nuxt = useNuxtApp()
  const { $api, $i18n } = nuxt
  const { setRouteParams } = useSetRouteParams()

  const fetchDestination = async (destinationName) => {
    try {
      const res = await $api.v3.destination.getOneBySlug({
        slug: destinationName,
        fields: {
          destination: ['id', 'searchUrl'],
        },
      })

      if (res?.data) {
        const searchUrl = res.data.searchUrl

        return getDestinationSlug(searchUrl)
      }

      throw showError({ statusCode: 404, message: err.message })
    } catch (err) {
      throw showError({ statusCode: 404, message: err.message })
    }
  }

  try {
    const house = await $api.v3.house.getHouseBySlug({
      name: to.params.slug,
      token: to?.query['private-token'] || null,
      fields: {
        house: ['id', 'slug'],
        destination: ['id', 'isCountry', 'isCluster', 'name'],
      },
      includes: ['destination', 'destination.country', 'destination.cluster'],
    })

    let destinations = house?.included?.destinations || []
    destinations = Array.isArray(destinations) ? destinations : [destinations]

    const [country] = destinations.filter((item) => item.isCountry)
    const [cluster] = destinations.filter((item) => item.isCluster)
    const [destination] = destinations.filter(
      (item) => !item.isCountry && !item.isCluster,
    )

    to.meta.ancestries = {
      clusterId: cluster?.id,
      clusterName: cluster?.name,
      countryId: country?.id,
      countryName: country?.name,
      destinationId: destination?.id,
      destinationName: destination?.name,
    }

    setRouteParams({
      slugEn: house.data.slug.en,
      slugFr: house.data.slug.fr,
    })
  } catch (err) {
    if (err?.response?.data?.errors.length > 0) {
      const error = err?.response?.data?.errors[0]

      if (error?.slug) {
        const redirect = nuxt.$localePath({
          name: 'house-slug',
          params: { slug: error.slug[$i18n.locale.value] },
        })

        return navigateTo(redirect, { redirectCode: 301 })
      } else if (
        error?.destinationId &&
        error?.status &&
        ['offboarded', 'hidden'].includes(error?.status)
      ) {
        const destination = await fetchDestination(error.destinationId)

        return navigateTo(destination[$i18n.locale.value].path, {
          redirectCode: 301,
        })
      }

      throw showError({ statusCode: 404, message: error.message })
    }
  }
})
